var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-bg-secondary workWithUs"},[_c('b-breadcrumb',{staticClass:"container mx-auto",staticStyle:{"margin-bottom":"0","border-radius":"0"}},[_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(_vm._s(_vm.$t("navBar.label.workWithPASH")))])],1),_c('div',{staticClass:"workWithUs_bg"},[_c('b-container',[_c('div',{staticClass:"text-white has-text"},[_c('h3',[_vm._v(_vm._s(_vm.$t("navBar.label.DifferentWaysOfWorkingWithPash")))])]),_c('div',{staticClass:"box-wrapper"},[_c('div',{staticClass:"work-box-wrapper"},[_c('b-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'Sell' }}},[_c('b-card',{staticClass:"text-black work-box"},[_c('h5',[_vm._v(_vm._s(_vm.$t("navBar.label.sellers")))])])],1)],1),_c('div',{staticClass:"work-box-wrapper"},[_c('b-link',{staticClass:"text-black",staticStyle:{"text-decoration":"none"},attrs:{"to":{
              name: 'Contact',
              params: { type: 'inspection-agents' },
            }}},[_c('b-card',{staticClass:"text-black work-box"},[_c('h5',[_vm._v(_vm._s(_vm.$t("navBar.label.inspectionAgents")))])])],1)],1),_c('div',{staticClass:"work-box-wrapper"},[_c('b-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
              name: 'Contact',
              params: { type: 'freight-forwarders' },
            }}},[_c('b-card',{staticClass:"text-black work-box"},[_c('h5',[_vm._v(_vm._s(_vm.$t("navBar.label.freightForwarders")))])])],1)],1),_c('div',{staticClass:"work-box-wrapper"},[_c('b-link',{staticClass:"h-100 d-block",staticStyle:{"text-decoration":"none"},attrs:{"to":{
              name: 'Contact',
              params: { type: 'customs-brokers' },
            }}},[_c('b-card',{staticClass:"text-black work-box"},[_c('h5',[_vm._v(_vm._s(_vm.$t("navBar.label.customsBrokers")))])])],1)],1),_c('div',{staticClass:"work-box-wrapper"},[_c('b-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
              name: 'Contact',
              params: { type: 'sales-representatives' },
            }}},[_c('b-card',{staticClass:"text-black work-box"},[_c('h5',[_vm._v(_vm._s(_vm.$t("navBar.label.salesRepresentatives")))])])],1)],1),_c('div',{staticClass:"work-box-wrapper"},[_c('b-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
              name: 'Contact',
              params: { type: 'jobs' },
            }}},[_c('b-card',{staticClass:"text-black work-box"},[_c('h5',[_vm._v(_vm._s(_vm.$t("navBar.label.jobs")))])])],1)],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }